<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout align-center class="pointer" row @click="selectService()">
		<w-flex shrink pr-2>
			<v-avatar :color="value.category.color" size="30px">
				<span class="caption font-weight-bold white--text" v-text="value.category.abbreviation" />
			</v-avatar>
		</w-flex>
		<w-flex
			:class="{
				'font-weight-bold': isSelected,
				'primary--text': isSelected
			}"
			grow
			v-html="displayedName"
		>
		</w-flex>
		<w-flex xs3>
			<w-list-tile-action style="justify-content: flex-end">{{ $n(value.price, 'currency') }} </w-list-tile-action>
		</w-flex>
	</w-layout>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'ServiceItem',
	mixins: [ProjectsManagerModuleGuard],
	props: {
		isSelected: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			required: true,
			type: Object
		}
	},
	computed: {
		search: {
			get: function () {
				return this.$store.state.customers.projects.filters.search
			},
			set: function (val) {
				this.$store.dispatch('customers/projects/setSearchedText', val)
			}
		},
		displayedName: function () {
			if (!this.search) {
				return this.value.title
			}
			const searchedTextRegEx = new RegExp(this.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')
			return this.value.title.replace(searchedTextRegEx, '<b>$&</b>')
		}
	},
	methods: {
		selectService: function () {
			return this.service.selectService(this.value.project_id, this.value.id)
		}
	}
}
</script>
